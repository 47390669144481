<template>
  <ASmoothReflow class="promo-code__wrapper">
    <div
      :class="['promo-code', {
        'promo-code--applied': isPromoCodeApplied,
        'promo-code--invalid': !isPromoCodeValid,
        'promo-code--pending': isPending
      }]"
    >
      <div
        :class="['promo-code__cover', { 'promo-code--visible': showCover }]"
        data-transaction-name="PromoCode - Add"
        @click="addPromoPressed"
      >
        <template v-if="isPromoCodeApplied">
        <span class="promo-code__cover-text">
          {{ $t('Promo code applied') }}
        </span>
          <SfButton
            v-if="allowPromoCodeRemoval"
            data-transaction-name="PromoCode - Remove"
            @click="removePromoCode"
            class="sf-button--pure promo-code__button"
          >
            <span class="promo-code__button--trash"></span>
          </SfButton>
        </template>

        <template v-else>
          <span class="promo-code-icon" />
          <span class="promo-code__cover-text">
          {{ $t('Add promo code') }}
        </span>
        </template>
      </div>

      <div :class="['promo-code__field', { 'promo-code--visible': !showCover }]">
        <SfInput
          ref="promoCode"
          v-model="promoCode"
          name="promoCode"
          :label="$t('Promo code')"
          :error-message="errorMessage"
          :valid="isPromoCodeValid"
          class="sf-input--filled promo-code__input"
          data-transaction-name="PromoCode - Field"
          @keyup="cleanError"
          @keyup.enter="applyPromoCode"
        />
      </div>
    </div>

    <div v-if="!showCover" class="promo-code__action">
      <SfButton
        @click="applyPromoCode"
        data-transaction-name="PromoCode - Apply"
        class="sf-button--pure promo-code__action-save"
      >
        {{ $t('Add') }}
      </SfButton>

      <SfButton
        @click="hidePromoCode"
        data-transaction-name="PromoCode - Hide"
        class="sf-button--pure promo-code__action-hide"
      >
        {{ $t('Close') }}
      </SfButton>
    </div>

    <ALoadingSpinner
      class="promo-code__loading"
      v-show="isPending"
      :is-absolute-position="true"
    />
  </ASmoothReflow>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import { mapActions, mapGetters } from 'vuex';
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner';
import SfInput from 'theme/components/storefront-override/SfInput'
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';

export default {
  name: 'APromoCode',
  components: {
    ASmoothReflow,
    SfInput,
    SfButton,
    ALoadingSpinner
  },
  props: {
    allowPromoCodeRemoval: {
      type: Boolean,
      default: true
    }
  },
  directives: {
    clickOutside
  },
  data () {
    return {
      promoCode: '',
      isAddPromoPressed: false,
      isPromoCodeValid: true,
      promoCodeMessage: null,
      isPending: false
    };
  },
  computed: {
    ...mapGetters({
      isPromoCodeApplied: 'cart/getCoupon'
    }),
    showCover () {
      return !this.isAddPromoPressed || this.isPromoCodeApplied;
    },
    errorMessage () {
      if (this.promoCode.length === 0) return this.$t('Field is required')

      if (this.promoCodeMessage) return this.$t(this.promoCodeMessage)

      return this.$t('The coupon code isn\'t valid. Verify the code and try again.')
    }
  },
  methods: {
    ...mapActions('cart', ['applyCoupon', 'removeCoupon']),
    cleanError () {
      this.isPromoCodeValid = true
      this.promoCodeMessage = null
    },
    async applyPromoCode () {
      if (this.promoCode.length === 0) {
        this.isPromoCodeValid = false;
        return;
      }

      const { result, code } = await this.pendingMiddleware(this.applyCoupon, this.promoCode);

      if (typeof result === 'string') {
        this.promoCodeMessage = result
      }

      if ((typeof result === 'boolean' && !result) || code !== 200) {
        this.isPromoCodeValid = false;
      }
    },
    async removePromoCode () {
      await this.pendingMiddleware(this.removeCoupon);
      this.isAddPromoPressed = false;
      this.promoCode = '';
      this.isPromoCodeValid = true;
    },
    async hidePromoCode () {
      this.isAddPromoPressed = false;
      this.isPromoCodeValid = true;
      this.promoCode = '';
    },
    addPromoPressed () {
      this.isAddPromoPressed = true

      this.$nextTick(() => {
        this.$refs.promoCode.$el.querySelector('input').focus()
      })
    },
    async pendingMiddleware (fn, ...args) {
      if (!this.isPromoCodeValid) {
        return
      }

      this.isPending = true;
      const payload = await fn(args);
      this.isPending = false;

      return payload;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import '~theme/css/fonts';

@mixin positionAbsolute($top: 0, $bottom: 0, $left: 0, $right: 0) {
  position: absolute;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
}

.promo-code {
  box-sizing: border-box;
  position: relative;
  height: var(--spacer-50);
  transition: opacity 0.3s ease-in-out;

  &__wrapper {
    position: relative;
  }

  &-icon {
    @include font-icon(var(--icon-percent-new));

    &:before {
      color: var(--orange);
      font-size: var(--font-size-29);
    }
  }

  &--invalid {
    margin-bottom: var(--spacer-35);

    @include for-tablet {
      margin-bottom: var(--spacer-30);
    }
  }

  &:not(.promo-code--invalid) {
    .promo-code__field {
      ::v-deep .sf-input__error-message {
        display: none;
      }
    }
  }

  &--pending {
    opacity: 0.5;
    pointer-events: none;
  }

  &__field,
  &__cover {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &:not(.promo-code--visible) {
      pointer-events: none;
    }
  }

  &--visible {
    opacity: 1;
  }

  &__field {
    @include positionAbsolute();

    .promo-code__input {
      ::v-deep input {
        height: var(--spacer-50);
      }
    }
  }

  &__button {
    position: absolute;
    --button-color: var(--c-primary);
    --button-font-size: var(--font-sm);
    --button-text-decoration: none;
    font-weight: var(--font-normal);
    top: 0.9rem;
    bottom: auto;
    right: 0;

    &--trash {
      @include font-icon(var(--icon-trash));

      &:before {
        font-size: var(--font-size-18);
        color: var(--dark-gray);
        transition: color .3s ease;
      }
    }
  }

  &__cover {
    @include positionAbsolute();

    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--spacer-50);
    background-color: rgba(235, 103, 71, .1);
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    width: 100%;

    &:before, &:after {
      content: '';
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: var(--white);
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &:before {
      left: 0;
      transform: translateX(-50%);
    }

    &:after {
      right: 0;
      transform: translateX(50%);
    }

    &--filled {
      cursor: default;
    }

    &-text {
      font-size: var(--font-sm);
      color: var(--orange);
      padding-left: 0.813rem;
    }
  }

  &--applied {
    .promo-code__cover {
      cursor: default;
      justify-content: space-between;
    }

    .promo-code__cover-text {
      color: var(--dark);
      font-weight: var(--font-normal);
      padding-left: var(--spacer-20);
      display: flex;
      align-items: center;
      height: 100%;
    }

    .promo-code__button {
      right: var(--spacer-20);
    }
  }

  &__action {
    display: flex;
    margin-top: var(--spacer-12);

    &-save {
      display: flex;
      min-height: var(--spacer-40);
      padding: var(--spacer-8) var(--spacer-16);
      font-size: var(--font-size-14);
      border: 1px solid var(--orange);
    }

    &-hide {
      display: flex;
      min-height: var(--spacer-40);
      padding: var(--spacer-8) var(--spacer-16);
      font-size: var(--font-size-14);
      font-weight: 400;
      color: var(--orange);
    }
  }
}
</style>
