<template>
  <div>
    <div
      class="o-order-step o-order-step-package"
      :class="{'o-order-step--filled': isFilled && !edited}"
    >
      <SfHeading
        :title="$t('Replacement and packaging of goods')"
        :level="2"
        class="o-order-step__title o-order-step__title--package"
        :class="{'o-order-step__title--default': isDefault}"
      >
        <template v-if="isFilled && !edited" #subtitle>
          <div class="o-order-step-content__row o-order-step-content__row--align-left o-order-step-content__row--edit">
            <SfButton
              @click="edit"
              data-transaction-name="Checkout - Step Package - Edit"
              class="sf-button--pure o-order-step__btn--edit"
            >
              {{ $t('Edit order') }}
            </SfButton>
          </div>
        </template>
      </SfHeading>
      <ASmoothReflow
        class="o-order-step__content"
      >
        <template v-if="edited">
          <div class="o-order-step-content__row">
            {{subtitle}}
          </div>
          <div class="o-order-step-content__row">
            <div class="radio-switcher-simple">
              <SfRadio
                v-for="action in replaceProductActions"
                v-model="packageProduct.replace"
                :key="action.code"
                :value="action.code"
                class="radio-switcher-simple__input"
              >
                <template #label>
                  <div class="sf-radio__label radio-switcher-square__label">
                    <div>{{ $t(action.title) }}</div>
                  </div>
                </template>
              </SfRadio>
            </div>
          </div>
          <div class="o-order-step-content__end-m-24">
            <button class="o-order-step-content__comment-button" @click="showComment = !showComment">
              {{ $t(showCommentButtonLabel) }}
            </button>
            <ASmoothReflow>
              <ATextarea
                v-if="showComment"
                :placeholder="$t('Comment to the collector')"
                v-model="packageProduct.comment"
                id="package_comment"
                name="package-comment"
                class="a-textarea--filled o-order-step-content__top-m-10"
                :min-height="89"
                @clear="clearComment"
              />
            </ASmoothReflow>
          </div>
          <div class="o-order-step-content__row">
            <SfButton
              class="sf-button--primary sf-button--full-width-mobile form__action-button"
              :disabled="$v.packageProduct.$invalid"
              data-transaction-name="Checkout - Step Package - Save"
              @click="sendDataToCheckout()"
            >
              <template v-if="isFilled">
                {{ $t("Save changes") }}
              </template>
              <template v-else>
                {{ $t("Continue") }}
              </template>
            </SfButton>
          </div>
        </template>
        <template v-if="isFilled && !edited">
          <div
            v-if="packageProduct.replace"
            class="o-order-step-content__row short-description"
          >
            {{ $t(replacementTitle) }}
          </div>
        </template>
      </ASmoothReflow>
    </div>
    <ASmoothReflow>
      <ACheckoutMessage
        v-if="isFilled || edited"
        :message="$t(packageProduct.replace + '_notice')"
        variant="warning"
      />
    </ASmoothReflow>
  </div>
</template>

<script>
import {
  SfButton,
  SfHeading,
  SfRadio
} from '@storefront-ui/vue'
import ATextarea from 'theme/components/atoms/a-textarea'
import config from 'config'
import { prepareCategoryProduct } from 'theme/helpers'
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { getCheckoutSessionItemByKey } from 'theme/store/checkout/helpers/session'
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';
import ACheckoutMessage from 'theme/components/atoms/a-checkout-message.vue';

export default {
  name: 'OOrderStepPackage',
  components: {
    ACheckoutMessage,
    ASmoothReflow,
    SfRadio,
    SfHeading,
    ATextarea,
    SfButton
  },
  data () {
    return {
      showComment: false,
      packageProduct: {
        replace: '',
        packaging: '',
        product: {},
        comment: ''
      },
      packages: [],
      packagingProductActions: [],
      isFilled: false
    }
  },
  validations () {
    return {
      packageProduct: {
        replace: {
          required
        }
      }
    }
  },
  beforeMount () {
    this.$bus.$on('checkout-after-load', this.onCheckoutLoad)
    this.$bus.$on('user-after-logout', this.resetCheckout)
    this.$bus.$on('shipping-dropped', this.resetCheckout)
  },
  beforeDestroy () {
    this.$bus.$off('checkout-after-load', this.onCheckoutLoad)
    this.$bus.$off('user-after-logout', this.resetCheckout)
    this.$bus.$off('shipping-dropped', this.resetCheckout)
  },
  props: {
    getActiveStep: {
      required: true,
      type: String,
      default: () => ''
    },
    shippingDetails: {
      required: true,
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    showCommentButtonLabel () {
      return this.showComment ? 'Hide comment to the collector' : 'Show comment to the collector'
    },
    replaceProductActions () {
      return config.checkout?.replaceProductActions || []
    },
    replacementTitle () {
      const replacementOptions = config.checkout.replaceProductActions || []
      const replacement = replacementOptions?.find(item => item.code === this.packageProduct.replace) || []
      return replacement?.title || ''
    },
    subtitle () {
      return this.edited ? this.$t('Specify your preferences') : ''
    },
    edited () {
      return this.getActiveStep === 'package'
    },
    isDefault () {
      return !this.edited && !this.isFilled
    }
  },
  methods: {
    ...mapActions({
      loadPackages: 'product/loadPackages'
    }),
    handlerChoosePackage (product) {
      this.packageProduct.product = product
    },
    sendDataToCheckout (onCheckoutLoad = false) {
      this.isFilled = true
      this.$bus.$emit('checkout-after-package', { data: this.packageProduct, onCheckoutLoad })
    },
    edit () {
      this.$bus.$emit('checkout-before-edit', 'package')
    },
    setDefaultOptions () {
      if (!this.packageProduct?.packaging) {
        const packaging = this.packagingProductActions?.find(item => item.isDefault)
        this.packageProduct.packaging = packaging?.code
      }
      if (!this.packageProduct?.replace) {
        const replacement = this.replaceProductActions?.find(item => item.isDefault)
        this.packageProduct.replace = replacement?.code
      }
    },
    async setPackages () {
      const result = await this.loadPackages()
      this.packages = result.items.map(prepareCategoryProduct)
      if (this.packages.length) this.handlerChoosePackage(this.packages[0])
    },
    setPackagingProductActions () {
      if (!this.shippingDetails.shippingMethodType) return

      if (this.packages.length) {
        this.packagingProductActions = config.checkout?.packagingProductActions[this.shippingDetails.shippingMethodType]
      } else {
        this.packagingProductActions = config.checkout?.packagingProductActions[this.shippingDetails.shippingMethodType]?.filter(item => {
          return item.code !== 'other_packages'
        })
      }
    },
    async shippingDetailsHandler () {
      await this.setPackages()
      this.setPackagingProductActions()
    },
    clearComment () {
      this.packageProduct.comment = '';
    },
    onCheckoutLoad () {
      const sessionData = getCheckoutSessionItemByKey('packagingDetails')
      if (sessionData) {
        this.packageProduct = sessionData
        this.sendDataToCheckout(true)
      }
      this.setDefaultOptions()
    },
    resetCheckout () {
      this.isFilled = false
    }
  },
  watch: {
    edited: {
      immediate: true,
      handler: function (value) {
        if (value) this.setDefaultOptions()
      }
    },
    shippingDetails (details) {
      if (details.shippingMethodType) this.shippingDetailsHandler()
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/components/organisms/o-order-step";
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-order-step-package {
  &__info-block {
    --info-block-background: rgba(255, 211, 56, 0.15);
    margin-bottom: 10px;
  }

  &-list {
    overflow: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(235, 103, 71, 0.3);
    }

    &__wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 0 var(--spacer-25);
      @media only screen and (min-width: $tablet-min) {
        padding: var(--spacer-5) var(--spacer-25);
      }
    }

    &-product {
      min-width: 150px;
      width: 100%;
      margin: var(--spacer-5);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__image {
        text-align: center;
        width: px2rem(150);
        height: px2rem(150);
      }

      &__title {
        font-size: var(--font-size-14);
        line-height: var(--font-size-17);
        color: var(--black);
        min-height: 2.26rem;
        display: block;
        position: relative;
        margin-bottom: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
      }

      &__input {
        &--radio {
          --radio-background: transparent;
          --radio-container-padding: 0;
          --radio-content-margin: 0 0 0 var(--spacer-10);
          margin: 0 0 var(--spacer-10);
        }
      }
    }
  }

  ::v-deep {
    .sf-radio {
      &__container {
      }

      &__content {
      }
    }
  }
}

.short-description {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;

  &__label {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
    line-height: var(--font-size-17);
    color: var(--dark-gray);
    flex: 0 0 90px;
    padding: 0 var(--spacer-10) 0 0;
    box-sizing: border-box;
  }

  &__value {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
    line-height: var(--font-size-17);
    color: var(--black);
    padding: 0 var(--spacer-10) 0 0;
    box-sizing: border-box;
  }
}
</style>
