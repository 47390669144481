var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ASmoothReflow',{staticClass:"m-price-summary"},[(_vm.nonGiftItemsQuantityWithQTY)?_c('SfProperty',{staticClass:"sf-property--full-width property property--total-items",class:{'sf-property--large': _vm.isLarge},attrs:{"name":_vm.$t('Cart amount total'),"value":_vm._f("price")(_vm.cartTotal)}}):_vm._e(),_vm._v(" "),(_vm.coupon)?_c('SfProperty',{staticClass:"sf-property--full-width property",class:{'sf-property--large': _vm.isLarge},attrs:{"name":_vm.coupon.label,"value":_vm._f("price")(_vm.coupon.amount)}}):_vm._e(),_vm._v(" "),(_vm.isNewPost)?_c('SfProperty',{staticClass:"sf-property--full-width property sf-property--np",class:{
      'sf-property--large': _vm.isLarge,
      'sf-property--over': _vm.isOverweight
    },attrs:{"value":_vm.$t('Delivery Plan')}},[_c('template',{slot:"name"},[_c('span',{staticClass:"sf-property__name"},[_vm._v("\n        "+_vm._s(_vm.deliveryLabel)+" "+_vm._s(_vm.totalWeightWithTranslate)+"\n        "),(_vm.maxWeight)?_c('span',{staticClass:"sf-property__name--sub"},[_vm._v("/ "+_vm._s(_vm.maxWeight))]):_vm._e()])])],2):((_vm.prices.shipping || _vm.prices.shipping === 0) && _vm.deliveryLabel)?_c('SfProperty',{staticClass:"sf-property--full-width property",class:{
      'sf-property--large': _vm.isLarge,
      'sf-property--over': _vm.isOverweight
    },attrs:{"value":_vm._f("price")(_vm.prices.shipping)}},[_c('template',{slot:"name"},[_c('span',{staticClass:"sf-property__name"},[_vm._v("\n        "+_vm._s(_vm.deliveryLabel)+" "+_vm._s(_vm.totalWeightWithTranslate)+"\n        "),(_vm.maxWeight)?_c('span',{staticClass:"sf-property__name--sub"},[_vm._v("/ "+_vm._s(_vm.maxWeight))]):_vm._e()])])],2):_vm._e(),_vm._v(" "),(_vm.prices.collecting)?_c('SfProperty',{staticClass:"sf-property--full-width property property--total-items",class:{'sf-property--large': _vm.isLarge},attrs:{"name":_vm.$t('Collecting'),"value":_vm._f("price")(_vm.prices.collecting)},scopedSlots:_vm._u([{key:"name",fn:function({ name }){return [_c('span',{staticClass:"sf-property__name"},[_vm._v("\n        "+_vm._s(name)+"\n        "),_c('AFloatingTooltip',{staticClass:"aft--full-width",scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$t('Collecting Tooltip'))+"\n          ")]},proxy:true}],null,true)},[_c('span',{staticClass:"m-price-summary__icon"})])],1)]}}],null,false,1130863538)}):_vm._e(),_vm._v(" "),(_vm.payment && _vm.paymentTips)?_c('SfProperty',{staticClass:"sf-property--full-width property",class:{'sf-property--large': _vm.isLarge},attrs:{"name":_vm.$t('Tips to the collector'),"value":_vm._f("price")(_vm.paymentTips)}}):_vm._e(),_vm._v(" "),_c('SfDivider',{staticClass:"divider"}),_vm._v(" "),_c('SfProperty',{staticClass:"sf-property--full-width property property--grand-total",class:{'sf-property--large': _vm.isLarge},attrs:{"name":_vm.$t('Total'),"value":_vm._f("price")(_vm.grandTotal)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }